import React from 'react';


import translateDangerous from '../utils/utilities';
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
// import translateDangerous from './utils/utilities';

const Applications = () => {
    
    const { t } = useTranslation();
    return (
        <div className='layout'>
            <h1>{i18n.t('i18n.main.OCAH')} </h1>
            <h1>{i18n.t('i18n.main.advanced_treatment')}</h1>
            <div id='benefits'> 
            <h1>{translateDangerous(i18n.t, 'i18n.main.benefits')}</h1>
            </div>
      </div>
    )
}

export default Applications;