import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'

import FadeInSection from './UI/FadeComp'
// import aband from '../assets/images/partner_Alves_Bandeira.png'
// import stex from '../assets/images/partner_stex.png'
import oxygen from '../assets/images/partner_oxygen.svg'
import klima from '../assets/images/klima_dao.svg'

import translateDangerous from '../utils/utilities';
import { useTranslation } from "react-i18next";


export default function Home() {
  const { t } = useTranslation();

  const oxygenURL = () => {
    window.open('https://oxygenchain.earth', '_blank')
  }

  const klimaURL = () => {
    window.open('https://www.klimadao.finance', '_blank')
  }

  return (
    <>
        <FadeInSection>
            <h1 className='title text-center'>{t('i18n.main.partners')}</h1>
           <Container className='partners_container' id='partners'>
                <Row className='text-center'>
                   <Col>
                    {/* <img  src={aband} className="partner_logos" alt="logo alves bandeira" /> */}
                    </Col>
                    <Col>
                    {/* <img  src={stex} className="partner_logos" alt="logo stex" /> */}
                    </Col>
                </Row>
                <Row className='text-center'>
                    <Col>
                      {/* <a href="/"> */}
                      {/* <img  src={klima} className="partner_logos" alt='logo klima dao' onClick={klimaURL} style={{cursor: 'pointer'}}/>
                      <span> &nbsp;</span>    */}
                       <span> &nbsp;</span> 
                      <div >
                       <span> &nbsp;</span> 
                        {/* add a line return below */}
                        <br/>
                        <br/>
                        <span className='partner_logos' onClick={klimaURL}
                        // add font of size 40 and weight 700 add padding top 10px
                        style={{cursor: 'pointer', fontSize: '40px', fontWeight: '700'}}>
                        
                        &nbsp;  &nbsp; KlimaDAO
                        </span>
                      </div>

                      {/* </a> */}
                    </Col> 
                    <Col>
                    
                    <img  src={oxygen} className="partner_logos" alt='logo oxygen' onClick={oxygenURL} style={{cursor: 'pointer'}}/>
                    </Col>
                </Row>
            </Container>

        </FadeInSection>
    </>
  )
}
