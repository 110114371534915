import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useState } from 'react';
// import logo from '../assets/images/logo.svg'
import logo from '../assets/images/ocha_darkgrey.jpeg'
import { useTranslation } from "react-i18next";

// import '../App.css';


function Navbars() {
    const { t, i18n } = useTranslation();
    const [language, setlanguage] = useState('en_us')
  
    const [show, setShow] = useState(false);
  
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
  

  
  
      function handleLangClick(lang) {
        i18n.changeLanguage(lang);
        setlanguage(lang)
        // alert(lang)
      }
    return (
      <>
      <Navbar bg="bg-xcrow" expand="lg" variant="dark">
        <Container >
          <Navbar.Brand href="#">
            <img src={logo} alt='logo' className='nav-logo'  />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '200px' }}
              navbarScroll
            >
            
            </Nav>
            <Nav.Link className='navlinkNormal' href="#">{t('i18n.main.home')}</Nav.Link>
              <Nav.Link className='navlinkNormal' href="#applications" >{t('i18n.main.applications')}</Nav.Link>
              <Nav.Link className='navlinkNormal' href="#system">{t('i18n.main.system')}</Nav.Link>
              <Nav.Link className='navlinkNormal' href='#partners' >{t('i18n.main.partners')}</Nav.Link>
              <Nav.Link className='navlinkNormal' href='#contact' >{t('i18n.main.contact')}</Nav.Link>
              <div className="navlinkNormal" style={{width:'200px'}}> 
                    <div onClick={() => handleLangClick("en_us")} >EN</div>&nbsp;&nbsp;|&nbsp;&nbsp;
                    <div onClick={() => handleLangClick("pt_br")} > PT </div>
              </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
             
      </>
    );
  }
  
  export default Navbars;