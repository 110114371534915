// import { useState, useEffect } from 'react';
// import logo from './logo.svg';

import './index.css'
import Navbars from './components/Navbars'
import AppHeader from './components/App'
import Applications from './components/Applications'
import Partners from './components/Partners'
import Contact from './components/Contact'
import System from './components/System'
import Footer from './components/Footer'
import React from 'react';


// import { withTranslation } from "react-i18next";
// import i18n from 'i18next';
// import translateDangerous from './utils/utilities';
// import { Col, Row, Container } from 'react-bootstrap'

import './App.css';

import Wave from './components/wave.component.js';
 


class App extends React.Component {
  // const { t } = useTranslation();

  state = {
		speed: .5,
		color: 'orange',
		height: 50,
		scale: 4
	}

	render() {
    // const { t } = this.props;
		// const controlStyle = {
		// 	display: 'inline-block',
		// 	padding: '16px'
		// };

		return ( 
          <div className="App"> 
              <Navbars />
                  <div>
                      <Wave
                        {...this.state}
                        style={{width: '100vw', height: '800px',  top: 0, left: 0}}
                      />
                      <AppHeader />
                    </div>

            <Applications />
            <System />
            <Partners />
            <Contact />
            <Footer />
            </div>
          )
      }
        
}


// function App() {
//   // const [waveConfig, setWaveConfig] = useState({
//   //                                     speed: 1,
//   //                                     color: 0xffffff,
//   //                                     height: 50,
//   //                                     scale: 4
//   //                                   })


//   return (
//     <div className="App">
//       	<Wave
// 					{...waveConfig}
// 					style={{width: '100vw', height: '90vh', backgroundColor: 'black'}}
// 				/>

//     {/* <div style={{height: '10vh'}}>
// 					<div style={controlStyle}>
// 						<label>Speed</label>
// 						<input
// 							type="range"
// 							step="1"
// 							value={this.state.speed}
// 							min="1"
// 							max="5"
// 							onChange={evt => setWaveConfig({speed: parseInt(evt.target.value)})}
// 						/>
// 					</div>

// 					<div style={controlStyle}>
// 						<label>Color</label>
// 						<input
// 							type="range"
// 							step="1"
// 							value={this.state.color}
// 							min={0x000000}
// 							max={0xffffff}
// 							onChange={evt => setWaveConfig({color: parseInt(evt.target.value)})}
// 						/>
// 					</div>

// 					<div style={controlStyle}>
// 						<label>Height</label>
// 						<input
// 							type="range"
// 							step="1"
// 							value={this.state.height}
// 							min="1"
// 							max="1000"
// 							onChange={evt => setWaveConfig({height: parseInt(evt.target.value)})}
// 						/>
// 					</div>

// 					<div style={controlStyle}>
// 						<label>Scale</label>
// 						<input
// 							type="range"
// 							step="1"
// 							value={this.state.scale}
// 							min="1"
// 							max="50"
// 							onChange={evt => setWaveConfig({scale: parseInt(evt.target.value)})}
// 						/>
// 					</div>

// 				</div> */}








//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a> 
//       </header>

//     </div>
//   );
// }

export default App;
