import React from 'react';

import FadeInSection from './UI/FadeComp'
import Air from '../assets/images/air.svg'
import Water from '../assets/images/water.svg'
import Conservation from '../assets/images/rain-drop.svg'
import Solids from '../assets/images/solids.svg'
import Systems from '../assets/images/app_systems.png'
import Landfill from '../assets/images/app_landfill1.jpg'
import Landphase from '../assets/images/app_landphases.png'
import fridge from '../assets/images/app_cemitery_minifridge.jpg'
import fridgetruck from '../assets/images/app_cemeteryfridgetruck.png'
import soul from '../assets/images/app_cemetarysoul2.jpeg'
import martini from '../assets/images/app_necrolechate.jpg'
import indfilter from '../assets/images/app_indfilter.JPG'
import indSensors from '../assets/images/app_indsensors.JPG'
import indOsmosis from '../assets/images/app_indosmosis.JPG'
import indTreated from '../assets/images/app_industrial_treated.png'
import Landgif from '../assets/images/landfill_.gif'

import monitor from '../assets/images/app_monitor.jpg'
import moniorTime from '../assets/images/app_monitortime.jpg'
import ship from '../assets/images/app_oilship.jpeg'
import oiltreated from '../assets/images/app_oiltreated.jpg'
// import OcahF1 from '../assets/images/ocah_faze1.png'
// import OcahF2 from '../assets/images/ocah_faze2.png'
// import OcahF3 from '../assets/images/ocah_faze3.png'
// import translateDangerous from '../utils/utilities';
import { useTranslation } from "react-i18next";

const System = () => { 
    
    const { t } = useTranslation();
    return (
        <FadeInSection>
        <div className="phasesBox" id="system">
            <h1 className='text-center title '>{t('i18n.main.system')}</h1>
            <div className="phases mt-5"> 
                {/* <img src={Catalitic} alt="" style={{width: '100%'  , marginLeft: 'auto', marginRight: 'auto'}} /> */}
                <div className="sys-container">
                    <div className="sys-image-wrapper sys-image-large">
                        <img src={Landfill} alt="Image 1" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-small">
                        <img src={Systems} alt="Image 2" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-small">
                        <img src={Landphase} alt="Image 3" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-large sys-image-large-gif">
                        <img src={Landgif} alt="Animated" className="sys-image" />
                    </div>
                </div>
                <p>{t('i18n.main.system_combined')}</p>  
            </div>
            <p></p>
            <br/>
            <p>
                <h2 className='text-center title '>{t('i18n.main.faze1')}</h2>
            </p>
            <div className="phases mt-6"> 
                {/* <img src={OcahF1} alt="" style={{width: '100%'  , marginLeft: 'auto', marginRight: 'auto'}} /> */}
                <div className="sys-container">
                    <div className="sys-image-wrapper sys-image-large">
                        <img src={fridge} alt="Image 1" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-small">
                        <img src={soul} alt="Image 2" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-small">
                        <img src={fridgetruck} alt="Image 3" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-large sys-image-large-gif">
                        <img src={martini} alt="Animated" className="sys-image" />
                    </div>
                </div>

                <p>{t('i18n.main.faze1_desc')}</p>  
            </div>
            <p></p>
            <br/>
            <p>
                <h2 className='text-center title '>{t('i18n.main.faze2')}</h2>
            </p>
            <div className="phases mt-6"> 
                {/* <img src={OcahF2} alt="" style={{width: '100%'  , marginLeft: 'auto', marginRight: 'auto'}} /> */}
                <div className="sys-container">
                    <div className="sys-image-wrapper sys-image-large">
                        <img src={indfilter} alt="Image 1" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-small">
                        <img src={indSensors} alt="Image 2" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-small">
                        <img src={indOsmosis} alt="Image 3" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-large sys-image-large-gif">
                        <img src={indTreated} alt="Animated" className="sys-image" />
                    </div>
                </div>

                <p>{t('i18n.main.faze2_desc')}</p>  
            </div>
            <p></p>
            <br/>
            <p>
                <h2 className='text-center title '>{t('i18n.main.faze3')}</h2>
            </p>
            <div className="phases mt-6"> 
                {/* <img src={OcahF3} alt="" style={{width: '100%'  , marginLeft: 'auto', marginRight: 'auto'}} /> */}
                <div className="sys-container">
                    <div className="sys-image-wrapper sys-image-large">
                        <img src={ship} alt="Image 1" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-small">
                        <img src={monitor} alt="Image 2" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-small">
                        <img src={moniorTime} alt="Image 3" className="sys-image" />
                    </div>
                    <div className="sys-image-wrapper sys-image-large sys-image-large-gif">
                        <img src={oiltreated} alt="Animated" className="sys-image" />
                    </div>
                </div>
                <p>{t('i18n.main.faze3_desc')}</p>  
            </div>
        </div>
        </FadeInSection>
    )
}

export default System;