import React from 'react';

import FadeInSection from './UI/FadeComp'
import chorume from '../assets/images/app_landfill1.jpg'
import necro from '../assets/images/app_cem1.png'
import agua from '../assets/images/app_oilywater.png'
import esgoto from '../assets/images/app_sewage2.jpeg'
import lama from '../assets/images/app_platform.jpeg'
import industria from '../assets/images/app_industrial2.jpg'
// import translateDangerous from '../utils/utilities';
import { useTranslation } from "react-i18next";

const Applications = () => {
    
    const { t } = useTranslation();
    return (
        <FadeInSection>
        <div className="phasesBox" id="applications">
            <h1 className='text-center title '>{t('i18n.main.applications')}</h1>
            <div className="phases mt-5">
                <div className="cardBlue">
                    <img src={chorume} alt="" />
                    <div>
                        <h3 className='diamond-text'>{t('i18n.main.app_leachate')}</h3>
                        <p>{t('i18n.main.app_leachate_desc')}</p>  
                    </div>
                </div>
                <div className="cardBlue">
                    <img src={necro} alt="" />  
                    <div>
                        <h3 className='diamond-text'>{t('i18n.main.app_necroleachate')}</h3>
                        <p>{t('i18n.main.app_necroleachate_desc')}</p>
                    </div>
                </div>
                <div className="cardBlue">
                    <img src={agua} alt="" />
                    <div>
                        <h3 className='diamond-text'>{t('i18n.main.app_oily')}</h3>
                        <p>{t('i18n.main.app_oily_desc')}</p>
                    </div>
                </div>
                <div className="cardBlue">
                    <img src={esgoto} alt="" />
                    <div>
                        <h3 className='diamond-text'>{t('i18n.main.app_sewage')}</h3>
                        <p>{t('i18n.main.app_sewage_desc')}</p> 
                    </div>
                </div>
                <div className="cardBlue">
                <img src={lama}  alt="" />
                    <div>
                        <h3 className='diamond-text'>{t('i18n.main.app_oilymud')}</h3>
                        <p>{t('i18n.main.app_oilymud_desc')}</p> 
                    </div>
                </div>
                <div className="cardBlue">
                <img src={industria}  alt="" />
                    <div>
                        <h3 className='diamond-text'>{t('i18n.main.app_industrial')}</h3>
                        <p>{t('i18n.main.app_industrial_desc')}</p> 
                    </div>
                </div>
            </div>
        </div>
        </FadeInSection>
    )
}

export default Applications;